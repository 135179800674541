import * as React from "react";
import SEO from "../components/SEO/SEO";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import MainNavbar from "../components/Headers/MainNavbar/MainNavbar";
import { Link } from "gatsby";

import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/mdbreact/scss/mdb-free.scss";

export default function NotFoundPage() {
	return (
		<>
			<MainNavbar />
			<SEO title="Edital não encontrado" />
			<div style={{ height: "80vh" }} className="d-flex align-items-center">
				<MDBContainer>
					<MDBRow>
						<MDBCol className="text-center font-weight-bold pb-4">
							<h1 style={{ fontSize: 48, color: "#1e973f" }}>Ops!</h1>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol className="text-center pb-4" style={{ color: "#6d6e72" }}>
							<p>
								Não foi possível
								<br />
								encontrar esse edital
							</p>
						</MDBCol>
					</MDBRow>
					{/* Mobile */}
					<MDBRow>
						<MDBCol col={12} md={6} className="text-center text-md-right">
							<Link className="text-decoration-none" to="/">
								<MDBBtn className="btn-yellow btn-block d-md-none px-4 py-3 mx-0 mb-3">
									<MDBIcon icon="chevron-circle-right" aria-hidden="true" />{" "}
									Voltar para o início
								</MDBBtn>
							</Link>
						</MDBCol>
						<MDBCol col={12} md={6} className="text-center text-md-left">
							<a
								className="text-decoration-none"
								href="https://www.feevale.br/institucional/fale-com-a-feevale"
							>
								<MDBBtn className="btn-yellow btn-block d-md-none px-4 py-3 mx-0">
									<MDBIcon icon="chevron-circle-right" aria-hidden="true" />{" "}
									Fale com a Feevale
								</MDBBtn>
							</a>
						</MDBCol>
					</MDBRow>
					{/* Desktop */}
					<MDBRow>
						<MDBCol col={12} md={6} className="text-center text-md-right">
							<Link className="text-decoration-none" to="/">
								<MDBBtn className="btn-yellow d-none d-md-inline px-4 py-3 mx-0">
									<MDBIcon icon="chevron-circle-right" aria-hidden="true" />{" "}
									Voltar para o início
								</MDBBtn>
							</Link>
						</MDBCol>
						<MDBCol col={12} md={6} className="text-center text-md-left">
							<a
								className="text-decoration-none"
								href="https://feevale.plusoftomni.com.br/api/chatsite/site/XVtMgYsroRAUYWhHsIeTq1qWYJ0aD5___CqiN1WNxxu8gwjKa2jtLA==/client"
							>
								<MDBBtn className="btn-yellow d-none d-md-inline px-4 py-3 mx-0">
									<MDBIcon icon="chevron-circle-right" aria-hidden="true" />{" "}
									Fale com a Feevale
								</MDBBtn>
							</a>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</div>
		</>
	);
}
